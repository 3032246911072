//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

.page{
    h1 {
        position: relative;
        padding: 0.5em;
        background: $primary;
        color: white;
        margin: 0;
        font-size: 1.5rem;
    }
    h2{
        color: $gray-600;
        font-size: 1rem;
        padding: 0.5rem;
        border-bottom: dashed 1px $gray-300;
    }
}
figcaption{
    color: $gray-500;
    font-size: 90%;
    text-align: center;
}
.page-content{
    line-height: 2.0rem;
    .wp-block-image {
        img{
        height: auto;
        }
    }
    .wp-block-embed__wrapper {
        @extend .ratio;
        @extend .ratio-16x9;
    }
    .googlemap
    {
        iframe {
        width: 100%;
        height: 350px;
        }
    }
    h2 {
        font-size: 1.5rem;
        padding: 0.8em 0.5em;
        background: transparent;
        border-left: solid 5px $primary;
        border-bottom: dashed 2px $gray-300;
        color: $primary;
        margin-bottom: 1.3rem;
    }
    h3 {
        font-size: 1.3rem;
        padding: 0.6em 0.6em;
        background: transparent;
        margin-bottom: 1.3rem;
        border-bottom: dashed 2px $gray-200;
    }
}
.wp-block-table
{
  margin: 10px 0;
  td:nth-child(1) {
    width: 30%;
  }
  td{
    padding: 10px;
  }
}

#nav-main,
footer{
    ul.menu, ul.sub-menu{
        @extend .list-group, .list-group-flush;
        li{
            @extend .list-group-item, .bg-transparent;
            a{
                @extend .text-light, .text-decoration-none;
            }
        }
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/icomoon";
@import "components/hamburgers";
